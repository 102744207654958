/*function popupYT(e) {
  "use strict";
  jQuery("a[onclick^='showVideoCode']").each(function () {
    var e = jQuery(this),
      t = e.attr("id").split("video_")[1],
      o = "hide_video_" + t,
      r = jQuery("#" + o).html();
    return e.attr("href", r).addClass("popup-youtube");
  }),
  1 === e && (jQuery("a[onclick^='showVideoCode']").each(function () {
      jQuery(this).removeAttr("onclick");
    }),
    jQuery("body").on("click", "a[id^='video_'].popup-youtube", function (e) {
      e.preventDefault();
      var t, o, r, i;
      (i = 11), (r = jQuery(this).attr("href")),
      (o = r.split("v=")),
       o.length > 1 ? (t = o[1].substr(0, i)) : r.indexOf("youtu.be") > -1 && ((o = r.split("youtu.be")),
       (t = o[1].substr(1, i))), 
       jQuery.extend(!0, jQuery.magnificPopup.defaults, {
         iframe: {
           patterns: {
             youtube: {
               index: "youtube.com/",
               id: t,
               src: "//www.youtube.com/embed/" + t + "?autoplay=1"
              }
            }
          }
        }),
        jQuery.magnificPopup.open({
          items: {
            src: "//www.youtube.com/embed/" + t, type: "iframe"
          }
        });
    })
  );
}*/

/*function popupYTtd(e) {
  "use strict";
  if (1 === e) {
    var t,
    width = jQuery(window).width();
    if (width > 900) {
      ((t = "popup-youtube"), jQuery("a[onclick^='showVideoCode']").each(function () {
        jQuery(this).removeAttr("onclick");
      }),
      jQuery("body").on("click", "a[id^='video_'].popup-youtube", function (e) {
        e.preventDefault();
        var t, o, r, i;
        i = 11;
        r = jQuery(this).attr("href");
        o = r.split("v=");
        if (o.length > 1) {
          t = o[1].substr(0, i);
        } else {
          r.indexOf("youtu.be") > -1 && ((o = r.split("youtu.be")), (t = o[1].substr(1, i))), jQuery.extend(!0, jQuery.magnificPopup.defaults, {
            iframe: {
              patterns: {
                youtube: {
                  index: "youtube.com/",
                  id: t,
                  src: "//www.youtube.com/embed/" + t + "?autoplay=1"
                }
              }
            }
          }),
          jQuery.magnificPopup.open({
            items: {
              src: "//www.youtube.com/embed/" + t,
              type: "iframe"
            }
          });
        }
      }))
    } else {
      ((t = "nopopup-youtube"),
      jQuery("body").on("click", ".jshop_video_thumb", function (e) {
        e.preventDefault();
        var t,
          o,
          r = jQuery(this).parent("a").attr("id"),
          i = "hide_" + r,
          a = jQuery("#" + i).html(),
          s = 11;
        (o = a.split("v=")), o.length > 1 ? (t = o[1].substr(0, s)) : a.indexOf("youtu.be") > -1 && ((o = a.split("youtu.be")), (t = o[1].substr(1, s))), jQuery("#" + i).html('<iframe width="300" height="225" src="https://www.youtube.com/embed/' + t + '" frameborder="0" allowfullscreen></iframe>');
      }));
    }
  }
  jQuery("a[onclick^='showVideoCode']").each(function () {
    var e = jQuery(this).attr("id").split("video_")[1],
      o = "hide_video_" + e,
      r = jQuery("#" + o).html();
    return jQuery(this).attr("href", r).addClass(t);
  });
}*/

function hideProd() {
  "use strict";
  var e = jQuery(".jshop.productfull").data("id"),
    t = jQuery(".mod-in-prod .dop_products .modopprod_item");
  t.each(function () {
    var t = jQuery(this);
    t.data("id") === e && t.hide();
  });
}

function rewriteSH() {
  "use strict";
  jQuery(".modal.large img:not([src^='http'])").each(function () {
    var e = jQuery(this);
    e.attr("src") &&
      e.attr("src", function (t, o) {
        "/" !== o.substr(0, 1) && e.attr("src", "/" + e.attr("src"));
      });
  }),
    jQuery(".modal.large a:not([href^='http:'],[href^='https:'],[href^='mailto:'],[href^='#'],[href*='://'])").each(function () {
      var e = jQuery(this);
      e.attr("href") &&
        e.attr("href", function (t, o) {
          "/" !== o.substr(0, 1) && e.attr("href", "/" + e.attr("href"));
        });
    });
}

/*function initMP() {
  "use strict";
  jQuery(".modal.large .app #list_product_image_middle a.lightbox:not([data-video])").magnificPopup({
    type: "image",
    prependTo: ".modal.large .app",
    gallery: {
      enabled: !0,
      navigateByImgClick: !0,
      arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
      tPrev: "Назад",
      tNext: "Вперед"
    },
    callbacks: {
      open: function () {
        jQuery(".modal.large .app .mfp-wrap").css({
          width: "80%",
          height: "100%",
          left: "0",
          right: "0",
          margin: "auto"
        });
      },
    },
  });
}*/

function isTouchDevice() {
  "use strict";
  return 1 == ("ontouchstart" in window || (window.DocumentTouch && document instanceof DocumentTouch));
}

/*function initMinCarusel() {
  "use strict";
  jQuery(".jshop_img_thumb").wrapAll("<div class='owl-carousel owl-height owl-thumb'></div>");
  var e = jQuery(".jshop.productfull .owl-carousel.owl-thumb");
  e.owlCarousel({loop: !1, margin: 5, nav: !0, navText: ['<i class="' + jQuery(".params-jshopkit").data("left-nav") + '"></i>', '<i class="' + jQuery(".params-jshopkit").data("right-nav") + '"></i>'], autoWidth: !1, autoplay: !1, autoplaySpeed: 1e3, center: !1, stagePadding: 0, dots: !1, dotData: !1, dotsEach: !1, lazyLoad: !1, autoplayHoverPause: !0, responsive: {0: {items: 2}, 600: {items: 3}, 1e3: {items: jQuery(".params-jshopkit").data("count-owl")}}}), jQuery(".jshop.productfull .owl-item img").css("border", "none"), jQuery(".jshop.productfull .owl-carousel .owl-stage-outer").css("background-color", "#ffffff"), jQuery(".jshop.productfull .owl-thumb .owl-prev").appendTo(".jshop.productfull .owl-thumb .owl-stage-outer"), jQuery(".jshop.productfull .owl-thumb .owl-next").appendTo(".jshop.productfull .owl-thumb .owl-stage-outer");
  $(".jshop_img_description .owl-carousel").owlCarousel();
}*/

/*function initRelCarusel() {
  "use strict";
  jQuery(".jshop_rel_prod").wrapAll("<div class='owl-carousel owl-height owl-rel'></div>");
  var e = jQuery(".jshop.productfull .owl-carousel.owl-rel");
  e.owlCarousel({loop: !1, margin: 5, nav: !0, navText: ['<i class="' + jQuery(".params-jshopkit").data("left-nav") + '"></i>', '<i class="' + jQuery(".params-jshopkit").data("right-nav") + '"></i>'], autoWidth: !1, autoplay: !1, autoplaySpeed: 1e3, center: !1, stagePadding: 0, dots: !1, dotData: !1, dotsEach: !1, lazyLoad: !1, autoplayHoverPause: !0, responsive: {0: {items: jQuery(".params-jshopkit").data("count-rel")}, 600: {items: jQuery(".params-jshopkit").data("count-rel")}, 1e3: {items: jQuery(".params-jshopkit").data("count-rel")}}}), jQuery(".jshop.productfull .owl-rel .owl-item img").css("border", "none"), jQuery(".jshop.productfull .owl-rel .owl-prev").appendTo(".jshop.productfull .owl-rel .owl-stage-outer"), jQuery(".jshop.productfull .owl-rel .owl-next").appendTo(".jshop.productfull .owl-rel .owl-stage-outer");
}*/

function rowHeightClear() {
  "use strict";
  jQuery(".rp-brooksite .jshop_list_product .row-fluid, .rp-brooksite .jshop_list_product .row").each(function () {
    var e = jQuery(this);
    e.css("min-height", "100%");
  });
}

function rowHeight() {
  "use strict";
  jQuery(".rp-brooksite .jshop_list_product .row-fluid, .rp-brooksite .jshop_list_product .row").each(function () {
    var e = jQuery(this);
    e.css("min-height", e.height() + "px");
  });
}

function rowHeightMl() {
  "use strict";
  jQuery(".rp-brooksite .modal.large .jshop_list_product .row-fluid, .rp-brooksite .modal.large .jshop_list_product .row").each(function () {
    var e = jQuery(this);
    e.css("min-height", e.height() + "px");
  });
}

function initTooltip() {
  "use strict";
  jQuery(".rp-brooksite").tooltip({selector: "[data-rel='tooltip'], [rel='tooltip']", trigger: "hover"});
}

jQuery(function () {
  "use strict";
  var e, t,
    o = jQuery('script[src*="plugins/system/joomshopkit/assets/js/zoomsl-3.0.min.js"]').length,
    r = jQuery('script[src*="plugins/system/joomshopkit/assets/js/jquery.elevatezoom.min.js"]').length,
    i = jQuery('script[src*="/js/owl.carousel.js"]').length,
    a = jQuery('script[src*="plugins/system/joomshopkit/assets/js/jquery.magnific-popup.min.js"]').length,
    s = jQuery('script[src*="/bootstrap.min.js"]').length,
    u = jQuery(".compare_foto a"),
    l = jQuery(".params-jshopkit").data("uad"),
    n = jQuery(".params-jshopkit").data("params"),
    p = jQuery(".params-jshopkit").data("rel");
  initTooltip(),
    jQuery("body.bs3").length
      ? ((e = ""),
        (t = "hidden.bs.modal"),
        jQuery(".type_view .modal.fade").removeClass("collapse"),
        jQuery(".rp-brooksite .jshop_register .control-group, .rp-brooksite .jshop_login .control-group").addClass("form-group"),
        jQuery(".rp-brooksite .jshop_register .control-label, .rp-brooksite .jshop_login .control-label, .rp-brooksite .jshop_searchform .control-label").addClass("col-sm-3"),
        jQuery(".rp-brooksite .jshop_register .controls, .rp-brooksite .jshop_login .controls, .rp-brooksite .jshop_searchform .controls").addClass("col-sm-9"),
        jQuery(".rp-brooksite .jshop_register select, .rp-brooksite .jshop_register input,\t.rp-brooksite .jshop_login select, .rp-brooksite .jshop_login input, .rp-brooksite .jshop_searchform select, .rp-brooksite .jshop_searchform input[type='text']").addClass("form-control"),
        jQuery("body").on("hidden.bs.modal", "#inlineContent_minicart_cart_view, #inlineContent_minicart_wishlist_view, #askquestion_modal", function () {
          jQuery(".modal-pqv.fade.in").length && jQuery("body").addClass("modal-open");
        }))
      : ((e = "collapse"), (t = "hide")),
    jQuery("body").append('<div id="pqv_modal" class="modal-pqv modal large fade ' + e + '" tabindex="-1" role="dialog" aria-labelledby="pqv_modalLabel" aria-hidden="true"><div class="modal-dialog modal-lg" role="document"><div class="modal-content"><div class="modal-header"><span class="pqv_more"></span><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button><h4></h4></div><div class="modal-body"><div class="app"></div></div></div></div></div>'),
    jQuery("body").on(t, "#pqv_modal", function () {
      jQuery(".modal.large .app").empty();
    }),
    jQuery(".modal").css({"z-index": 1050}),
    jQuery(".modal.large").css({"z-index": 1049}),
    jQuery("body.bs3").length ||
      (jQuery(".modal.large").css({
        width: function () {
          return 0.8 * jQuery(document).width() + "px";
        },
        "margin-left": function () {
          return -(jQuery(this).width() / 2);
        },
        "z-index": 1049,
      }),
      jQuery(".modal.large .modal-body").css({
        "max-height": function () {
          return 0.8 * jQuery(window).height() + "px";
        },
      }),
      1 === s && (jQuery.fn.modal.Constructor.prototype.enforceFocus = function () {})),
    jQuery("body").on("click", "a.inline_pqv", function (e) {
      e.preventDefault();
      var t,
        o = jQuery(this),
        r = o.data("pqv"),
        s = o.data("pqvmore"),
        u = o.data("pqvmoretext");
      (t = jQuery(".bs3_v5").length ? jQuery(".brooksite-header .top-navbar").outerHeight() : jQuery(".brooksite-header").outerHeight()),
        jQuery(".modal.large .modal-content").css("top", t),
        jQuery(".modal.large .app").load(r, function () {
          rewriteSH(),
            rowHeightMl(),
            1 === i && "on" === n && (initMinCarusel(), "on" === p && (initRelCarusel(), jQuery(".rel-prod.fade-to").fadeTo(1500, 1))),
            1 === a &&
              (jQuery(document).ajaxComplete(function (e, t, o) {
                o.url.indexOf("ajax_attrib_select_and_price") !== -1 && initMP();
              }),
              initMP()),
            jQuery(".pqv_more").html("<a href='" + s + "'>" + u + "</a>");
        });
    }),
    jQuery("body").on("click", ".modal.large .app .navigation_div a", function (e) {
      e.preventDefault();
      var t = jQuery(this),
        o = t.data("pqv"),
        r = t.data("pqvmore"),
        s = t.data("pqvmoretext");
      jQuery(".modal.large .app")
        .empty()
        .load(o, function () {
          rewriteSH(),
            1 === i && "on" === n && (initMinCarusel(), "on" === p && (initRelCarusel(), jQuery(".rel-prod.fade-to").fadeTo(1500, 1))),
            1 === a &&
              (jQuery(document).ajaxComplete(function (e, t, o) {
                o.url.indexOf("ajax_attrib_select_and_price") !== -1 && initMP();
              }),
              initMP()),
            jQuery(".pqv_more").html("<a href='" + r + "'>" + s + "</a>");
        });
    }),
    1 === a &&
      (u.each(function () {
        jQuery(this).attr("data-mfp-src", jQuery(this).attr("href"));
      }),
      u.magnificPopup({type: "image"})),
    jQuery("body").on("click", "button.list-btn.cp", function () {
      jQuery(".list-btn.button.cp:not('.compare_dnone')").trigger("click");
    }),
    jQuery(".btn.list-btn.button.remove_comp:not('.compare_dnone')").length && jQuery("button.list-btn.cp").attr("data-original-title", "Перейти к сравнению"),
    jQuery(".btn.list-btn.button.remove_comp.compare_dnone").length && jQuery("button.list-btn.cp").attr("data-original-title", "Добавить к сравнению"),
    jQuery("body").on("click", "#rpBrooksTab a", function (e) {
      e.preventDefault(), jQuery(this).tab("show");
    }),
    jQuery("#rpBrooksCollaps").collapse(),
    jQuery("body").on("click", ".to-list", function (e) {
      jQuery(e.target).parent().css("position", "relative").prepend("<i style='position:absolute; right:0;' class='fa fa-spinner fa-pulse fa-2x fa-fw'></i>"),
        jQuery.ajax({
          url: window.location.href,
          type: "POST",
          data: {toView: "toList"},
          success: function (e) {
            (e = "toList") && window.location.reload();
          },
        });
    }),
    jQuery("body").on("click", ".to-grid", function (e) {
      jQuery(e.target).parent().css("position", "relative").prepend("<i style='position:absolute; right:0;' class='fa fa-spinner fa-pulse fa-2x fa-fw'></i>"),
        jQuery.ajax({
          url: window.location.href,
          type: "POST",
          data: {toView: "toGrid"},
          success: function (e) {
            (e = "toGrid") && window.location.reload();
          },
        });
    }),
    jQuery("body").on("click", ".to-slim", function (e) {
      jQuery(e.target).parent().css("position", "relative").prepend("<i style='position:absolute; right:0;' class='fa fa-spinner fa-pulse fa-2x fa-fw'></i>"),
        jQuery.ajax({
          url: window.location.href,
          type: "POST",
          data: {toView: "toSlim"},
          success: function (e) {
            (e = "toSlim") && window.location.reload();
          },
        });
    }),
    (1 !== o && 1 !== r) ||
      jQuery("body.js-zoomer-off").length ||
      jQuery(document).ajaxComplete(function (e, t, o) {
        o.url.indexOf("ajax_attrib_select_and_price") !== -1 && (initDataListZoom(), o.url.indexOf("fid=afl") !== -1 || jQuery(".modal-pqv:visible").length || initDataZoom());
      }),
    1 === i &&
      "on" === n &&
      (0 !== l &&
        jQuery(document).ajaxComplete(function (e, t, o) {
          o.url.indexOf("ajax_attrib_select_and_price") !== -1 && o.url.indexOf("fid=afl") === -1 && initMinCarusel();
        }),
      initMinCarusel(),
      "on" === p && initRelCarusel()),
    jQuery(document).ajaxComplete(function (e, t, i) {
      i.url.indexOf("jsfilter") !== -1 && ((1 !== o && 1 !== r) || jQuery("body.js-zoomer-off").length || (initDataListZoom(), initDataZoom()), jQuery(".jshop_list_product").eq(0).addClass("rp-brooksite"), jQuery(".rp-filter").length > 1 ? (jQuery(".rp-filter").eq(0).css("display", "block"), jQuery(".rp-filter .box_products_sorting").eq(0).removeClass("span5 col-md-"), jQuery(".rp-filter i").parent("div").eq(0).remove()) : jQuery(".jshop_list_category").children().length && (jQuery(".rp-filter .box_products_sorting").eq(0).removeClass("span5 col-md-"), jQuery(".rp-filter i").parent("div").eq(0).remove()));
    });
    //isTouchDevice() === !0 ? (jQuery(".attrib").css("display", "block"), popupYTtd(a)) : (initTooltip(), popupYT(a)),
    isTouchDevice() === !0 ? (jQuery(".attrib").css("display", "block")) : (initTooltip()),
    hideProd();
});

jQuery(window).load(function () {
  "use strict";
  rowHeight(), jQuery(".rel-prod.fade-to").fadeTo(1500, 1);
});

jQuery(window).resize(function () {
  "use strict";
  rowHeightClear(), rowHeight();
});
